@media only screen and (min-width: 601px)
{

    

   body{
      overflow-x: hidden !important;
      width: 100% !important;
   }

   .developer-hal-ppl {
      height: auto;
      position: relative;
      width: 100% !important;
      max-width: 100%;
      box-sizing: border-box;
      filter: brightness(50%);
    }
   


   .developer-hal-logo{
      position: absolute;
      width: 6%;
      height: auto;
      top: 4%;
      left: 3%;
   }



   .simple-economy-mail-desktop{
      position: absolute;
      top: 2.5%;
      left: 82%;
      color: white;
      text-decoration: none;
      font-size: 20px;
      font-weight: lighter;
   }



   .carka-navbar-desktop{
      position: absolute;
      width: 100%;
      height: 0.1%;
      background-color: white;
      left: 0%;
      top: 8%;
      border-radius: 10px;
   }



   .navbar-desktop {
      position: absolute;
      top: 2%;
      left: 40%;
      white-space: nowrap;
  }
  
  .nav-link-desktop {
      display: inline-block;
      margin-right: 20%;
      text-decoration: none;
      color: white;
      font-weight: bold;
      padding: 10px;
      border-radius: 5px;
      transition: 0.3s;
      z-index: 999 !important;
  }
  
  .nav-link-desktop:last-child {
      margin-right: 0;
  }
  
  .nav-link-desktop:hover {
      background-color: #476ca3;
  }


  .desktop-h3-first{
      position: absolute;
      color: white;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      font-weight: lighter;
      font-size: 30px;
  }



  .desktop-h1-first{
   position: absolute;
   color: white;
   top: 40%;
   left: 50%;
   transform: translate(-50%, -50%);
   text-align: center;
   font-weight: bold;
   font-size: 80px;
}


.desktop-button-1{
   background-color: white;
   color: rgb(22, 22, 114);
   text-align: center;
   position: absolute;
   top: 55%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 10%;
   height: 5%;
   border-radius: 10px;
   border: none;
   font-weight: bold;
   font-size: 20px;
}

.desktop-button-1:hover{
   color: white;
   background-color: rgb(22, 22, 114);
   transition: all 0.3s ease;
   cursor: pointer;
}


.jecnicka{
   width: 20%;
   height: 20%;
   background-color: white;
   position: absolute;
   top: 90%;
   left: 10%;
   border-radius: 10px;
   color: rgb(22, 22, 114);
   font-weight: bold;
   font-size: 23px;
   display: flex;
   justify-content: center;
   align-items: center;
}



.dvojka {
   width: 20%;
   height: 20%;
   background-color: white;
   position: absolute;
   top: 90%;
   left: 30.02%;
   border-radius: 10px;
   color: rgb(22, 22, 114);
   font-weight: bold;
   font-size: 23px;
   display: flex;
   justify-content: center;
   align-items: center;
}



.trojka{
   width: 20%;
   height: 20%;
   background-color: white;
   position: absolute;
   top: 90%;
   left: 50%;
   border-radius: 10px;
   color: rgb(22, 22, 114);
   font-weight: bold;
   font-size: 23px;
   display: flex;
   justify-content: center;
   align-items: center;
}


.ctverka{
   width: 20%;
   height: 20%;
   background-color: white;
   position: absolute;
   top: 90%;
   left: 70%;
   border-radius: 10px;
   color: rgb(22, 22, 114);
   font-weight: bold;
   font-size: 23px;
   display: flex;
   justify-content: center;
   align-items: center;
}
  

.jecnicka, .dvojka, .trojka, .ctverka{
   transition: all 0.3s ease;
   cursor: pointer;
   box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
   z-index: 999 !important;
}

.jecnicka:hover, .dvojka:hover, .trojka:hover, .ctverka:hover{
   height: 25%;
   background-color: rgb(22, 22, 114);
   color: white;
   top: 87%;
}



.pokracovani-desktop{
   position: relative;
   width: 100%;
   height: 100vh;
   margin-top: 0%;
   background-color: white;
}




.desktop-sluzby-main-text{
   position: relative;
   text-align: left;
   color: rgb(22, 22, 114);
   margin-top: -35%;
   font-weight: normal;
   font-size: 25px;
   margin-bottom: 0%;
   margin-left: 10%;
}



.desktop-sluzby-second-text{
   position: relative;
   text-align: left;
   color: rgb(22, 22, 114);
   margin-top: 0%;
   font-weight: bold;
   font-size: 40px;
   margin-left: 10%;
}



.desktop-pozadi-2{
   position: absolute;
   width: 100%;
   height: 100%;
   top: 200%;
}



.obrazek1{
   position: relative;
   top: 150%;
   left: 10%;
   width: 35%;
   height: auto;
   border-radius: 10px;
   margin-top: 2%;
   margin-bottom: 3%;
}



.obrazek2{
   position: relative;
   top: 150%;
   left: 20%;
   width: 34%;
   height: auto;
   border-radius: 10px;
   margin-top: 2%;
   margin-bottom: 3%;
}




.desktop-reference-main-text{
   position: relative;
   text-align: left;
   color: rgb(22, 22, 114);
   margin-top: 38%;
   left: 8%;
   font-weight: bold;
   font-size: 70px;
   margin-bottom: 0%;
}



.desktop-reference-second-text{
   position: relative;
   text-align: left;
   color: rgb(22, 22, 114);
   margin-top: 0%;
   left: 8%;
   font-weight: normal;
   font-size: 20px;
   margin-bottom: 0%;
}




.desktop-reference-second-text2{
   position: relative;
   text-align: left;
   color: rgb(22, 22, 114);
   margin-top: 3%;
   left: 10%;
   font-weight: 500;
   font-size: 18px;
   margin-bottom: 0%;
   margin-right: 70%;
   opacity: 0.8;
}


.desktop-reference-second-text3{
   position: relative;
   text-align: left;
   color: rgb(22, 22, 114);
   margin-top: 1%;
   left: 10%;
   font-weight: bold;
   font-size: 17px;
   margin-bottom: 0%;
   text-decoration: underline;
   cursor: pointer;
}



.desktop-kontakt-background{
   position: relative;
   width: 100%;
   height: 100vh;
   margin-top: 15%;
   background-color: rgb(22, 22, 114);
}


.desktop-kontakt-main-text{
   position: relative;
   text-align: left;
   color: white;
   margin-top: -45%;
   font-weight: lighter;
   font-size: 18px;
   /* text-decoration: underline; */
   margin-bottom: 0%;
   margin-left: 10%;
}



.desktop-kontakt-second-text{
   position: relative;
   text-align: left;
   color: white;
   margin-top: 0%;
   font-weight: normal;
   font-size: 40px;
   margin-left: 10%;
}



.desktop-kontakt-1{
   position: relative;
   text-align: left;
   margin-left: 10%;
   color: white;
   margin-top: 10%;
   font-weight: normal;
   font-size: 25px;
   margin-bottom: 0%;
   margin-left: 10%;
   /* left: 29%; */
}





.desktop-kontakt-2{
   position: relative;
   text-align: left;
   margin-left: 10%;
   color: white;
   margin-top: 2%;
   font-weight: normal;
   font-size: 25px;
   margin-bottom: 0%;
   /* left: 29%; */
}


.desktop-kontakt-3{
   position: relative;
   text-align: center;
   color: white;
   margin-top: 11%;
   font-weight: normal;
   font-size: 20px;
   margin-bottom: 0%;
}


.desktop-mapa-footer{
   position: absolute;
   width: 35%;
   height: 35vh;
   margin-top: -29.3%;
   border-radius: 5px;
   border: none;
   left: 55%;
   filter: brightness(90%);
   cursor: pointer !important;
}

.desktop-mapa-footer:hover{
   filter: brightness(100%);
}


.desktop-kontakt-1-second{
   font-size: 20px;
}

::-webkit-scrollbar {
   width: 8px; /* Nastaví šířku scroll baru */
   height: 8px; /* Nastaví výšku scroll baru pro horizontální scroll */
 }
 
 ::-webkit-scrollbar-thumb {
   background-color: transparent; /* Skryje posuvník (thumb) */
   border-radius: 10px;
 }
 
 ::-webkit-scrollbar-track {
   background-color: transparent; /* Skryje stopu scroll baru */
 }

 
 .desktop-font-weigh-lighter{
   font-size: 12px !important;
   font-weight: normal !important;
   color: gold;
 }




.desktop-font-weigh-lighter-odkaz{
   font-size: 12px !important;
   font-weight: normal !important;
   color: gold;
}

























 
}