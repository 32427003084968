@media only screen and (min-width: 601px) and (max-width: 1420px) {



   .desktop-mapa-footer{
      display: none;
   }

   .desktop-h1-first, .desktop-h3-first{
      margin-right: -100%;
   }

   .navbar-desktop{
      display: none;
   }



  }
  