@media only screen and (max-width: 1023px) 

{

      
        .developer-hal-ppl,
        .developer-hal-logo,
        .simple-economy-mail-desktop,
        .carka-navbar-desktop,
        .navbar-desktop,
        .nav-link-desktop,
        .desktop-h3-first,
        .desktop-h1-first,
        .desktop-button-1,
        .jecnicka,
        .dvojka,
        .trojka,
        .ctverka,
        .pokracovani-desktop,
        .desktop-sluzby-main-text,
        .desktop-sluzby-second-text,
        .desktop-pozadi-2,
        .obrazek1,
        .obrazek2,
        .desktop-reference-main-text,
        .desktop-reference-second-text,
        .desktop-reference-second-text2,
        .desktop-reference-second-text3,
        .desktop-kontakt-background,
        .desktop-kontakt-main-text,
        .desktop-kontakt-second-text,
        .desktop-kontakt-1,
        .desktop-kontakt-3,
        .desktop-mapa-footer,
        .desktop-kontakt-1-second,
        .tablet-res1,
        .image-container-desktop-tablet,
        .image-text-desktop-tablet,
        .desktop-kontakt-2 {
          display: none !important;
        }
      
}