@media only screen and (max-width: 600px) 

{



   .react-body{
    position: absolute;
    z-index: 0;
    height: 450%;
    width: 100%;
    top: 0%;
    left: 0%;
   }


   .backround-image1 {
    position: relative;
    object-fit: cover;
    z-index: 1;
    filter: brightness(30%);
    width: 100%;
    max-height: 600px;
}



 .loginko{
    position: absolute;
    top: -13%;
    left: -15%;
    width: 100%;
    height: 55%;
    z-index: 999 !important;
 }



 .menu{
    position: absolute;
    top: 2.5%;
    left: 85%;
    width: 8%;
    height: auto;
    z-index: 999 !important;
 }



 .prvni-text{
    position: relative;
    text-align: left;
    margin-top: -105%;
    margin-left: 8%;
    color: white;
    z-index: 999 !important;
    font-size: 16px;
    font-weight: lighter !important;
 }





 .druhy-text{
    position: relative;
    text-align: left;
    margin-top: -2%;
    margin-left: 8%;
    color: white;
    z-index: 999 !important;
    font-size: 35px;
    font-weight: 500 !important;
    margin-right: 5%;
    font-family: system-ui;
}



 .carka-1{
    position: relative;
    width: 85%;
    height: 0.1vh;
    background-color: white;
    margin-top: 15%;
    margin-left: 7.5%;
    z-index: 999 !important;
 }



 .email-1{
    position: relative;
    text-align: left;
    margin-top: 6% !important;
    margin-left: 8%;
    color: white !important;
    z-index: 999 !important;
    font-size: 16px;
    font-weight: lighter !important;
    text-decoration: none !important;
 }


 .odkaz{
    text-decoration: none !important;
    color: white !important;
 }



 .more-info-1{
   color: rgb(0, 11, 71);
   background-color: white;
   z-index: 999 !important;
   width: 50%;
   height: 5vh;
   border-radius: 5px;
   border: none;
   position: relative;
   font-size: 15px;
   font-weight: 500;
   margin-left: 8%;
 }


 .carenka-1{
   position: relative;
   width: 100%;
   height: 0.1vh;
   margin-top: 28%;
   background-color: rgb(0, 11, 71);
   z-index: 999 !important;
 }



   .sluzby-1{
      position: relative;
      text-align: left;
      margin-top: 10%;
      margin-left: 8%;
      color: rgb(0, 11, 71);
      z-index: 999 !important;
      font-size: 35px;
      font-weight: 500 !important;
      font-family: system-ui;
   }




   .sluzby-2{
      position: relative;
      text-align: left;
      margin-top: -8%;
      margin-left: 8%;
      color: rgb(0, 11, 71);
      z-index: 999 !important;
      font-size: 16px;
      font-weight: lighter !important;
   }


   .sluzby-3{
      position: relative;
      text-align: left;
      margin-left: 20%;
      margin-top: 15%;
      color: rgb(0, 11, 71);
      z-index: 999 !important;
      font-size: 25px;
      font-weight: normal !important;
      font-family: system-ui;
   }


   .sluzby-4{
      position: relative;
      text-align: left;
      margin-left: 20%;
      margin-top: 10%;
      color: rgb(0, 11, 71);
      z-index: 999 !important;
      font-size: 25px;
      font-weight: normal !important;
      font-family: system-ui;
   }




   .carenka-2{
      position: relative;
      width: 100%;
      height: 0.1vh;
      margin-top: 15%;
      background-color: rgb(0, 11, 71);
      z-index: 999 !important;
    }




    .carenka-3{
      position: relative;
      width: 100%;
      height: 0.1vh;
      margin-top: 3%;
      background-color: rgb(0, 11, 71);
      z-index: 999 !important;
    }



   .obrazek-1{
      position: relative;
      width: 90%;
      height: auto;
      margin-top: 10%;
      border-radius: 5px;
      margin-left: 5%;
   }

   .obrazek-nika{
      position: relative;
      width: 90%;
      height: auto;
      margin-top: 10%;
      border-radius: 5px;
      margin-left: 5%;
   }


   .obrazek-3{
      position: relative;
      width: 90%;
      height: auto;
      margin-top: 10%;
      border-radius: 5px;
      margin-left: 5%;
   }


   .prvni-jmeno{
      position: relative;
      text-align: left;
      margin-left: 5%;
      color: rgb(0, 11, 71);
      z-index: 999 !important;
      font-size: 20px;
      font-family: system-ui;
      font-weight: lighter;
      margin-top: 1%;
   }



   .druhe-jmeno{
      position: relative;
      text-align: left;
      margin-left: 5%;
      color: rgb(0, 11, 71);
      z-index: 999 !important;
      font-size: 12px;
      font-family: system-ui;
      margin-top: -3%;
   }



   .sirsi{
      font-weight: 500 !important;
   }

   .sirsi-2{
      font-weight: 600 !important;
   }


   
   .tensi-2{
      position: relative;
      text-align: left;
      margin-left: 5%;
      color: rgb(0, 11, 71);
      z-index: 999 !important;
      font-size: 12px;
      font-family: system-ui;
      font-weight: 300 !important;
   }


   .more-info-2{
   color: white;
   background-color: rgb(0, 11, 71);
   z-index: 999 !important;
   width: 50%;
   height: 5vh;
   border-radius: 5px;
   border: none;
   position: relative;
   font-size: 15px;
   font-weight: 500;
   margin-left: 5%;
   margin-top: 10%;
   }




   .kontaktni-informace{
      position: relative;
      text-align: center;
      margin-top: 10%;
      color: rgb(0, 11, 71);
      z-index: 999 !important;
      font-size: 25px;
      font-weight: 500 !important;
      font-family: system-ui;
   }



   .mapicka{
      position: relative;
      width: 90%;
      height: 25vh;
      margin-left: 5%;
      border-radius: 5px;
      border: none;
   }



   .email-jedna{
      position: relative;
      text-align: left;
      margin-left: 10%;
      color: rgb(0, 11, 71);
      z-index: 999 !important;
      font-size: 20px;
      font-family: system-ui;
      font-weight: lighter;
      margin-top: 15%;
   }


   .dalsi-jedna{
      position: relative;
      text-align: left;
      margin-left: 10%;
      color: rgb(0, 11, 71);
      z-index: 999 !important;
      font-size: 20px;
      font-family: system-ui;
      font-weight: lighter;
      margin-top: 5%;
   }



   .podneco-jedna{
      position: relative;
      background-color: black;
      opacity: 0.1;
      width: 90%;
      height: 15vh;
      margin-top: 10%;
      margin-left: 5%;
      border-radius: 5px;
      z-index: 0;
   }




   .dalsi-jedna-dva{
      position: relative;
      text-align: center;
      color: rgb(0, 11, 71);
      z-index: 999 !important;
      font-size: 20px;
      font-family: system-ui;
      font-weight: lighter;
      margin-top: -25%;
   }



   .dalsi-jedna-tri{
      position: relative;
      text-align: center;
      color: rgb(0, 11, 71);
      z-index: 999 !important;
      font-size: 20px;
      font-family: system-ui;
      font-weight: lighter;
      margin-top: 0%;
   }


   .zaver{
      position: relative;
      text-align: center;
      color: rgb(0, 11, 71);
      z-index: 999 !important;
      font-size: 20px;
      font-family: system-ui;
      font-weight: normal;
      margin-top: 26%;
   }


   .odkaz2{
      text-decoration: none;
      color: rgb(0, 11, 71);
   }


   .cervena{
      color: red !important;
   }


   .mobile-font-weigh-lighter{
      color: darkgoldenrod !important;
      font-size: 10px !important;      
   }


   .mobile-font-weigh-lighter-odkaz{
      color: darkgoldenrod !important;
      font-size: 10px !important;
      /* text-decoration: underline 1px solid rgb(99, 71, 0); */
   }


  }
  