@media only screen and (min-width: 601px) 

{
      .react-body,
      .backround-image1,
      .loginko,
      .menu,
      .prvni-text,
      .druhy-text,
      .carka-1,
      .email-1,
      .odkaz,
      .more-info-1,
      .carenka-1,
      .sluzby-1,
      .sluzby-2,
      .sluzby-3,
      .sluzby-4,
      .carenka-2,
      .carenka-3,
      .obrazek-1,
      .obrazek-nika,
      .obrazek-3,
      .prvni-jmeno,
      .druhe-jmeno,
      .sirsi,
      .sirsi-2,
      .more-info-2,
      .kontaktni-informace,
      .mapicka,
      .email-jedna,
      .dalsi-jedna,
      .podneco-jedna,
      .dalsi-jedna-dva,
      .dalsi-jedna-tri,
      .zaver,
      .odkaz2,
      .tensi-2,
      .cervena {
          display: none;
  }
  

}