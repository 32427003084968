@media (min-width: 1000px) and (max-width: 1250px) {
    #sluzby.desktop-sluzby-main-text,
    .desktop-sluzby-second-text {
      position: static !important;
    }
  }
  

  
  @media only screen and (max-width: 1450px){

    .desktop-sluzby-second-text{
       margin-bottom: -10% !important;
    }
 
 }


 
 /* @media only screen and (max-width: 600px), 
 only screen and (min-width: 1450px) {
  .tablet-res1,
  .image-container-desktop-tablet,
  .image-text-desktop-tablet {
 display: none !important;
}
} */


@media (max-width: 1023px), (min-width: 1450px) {
  .image-container-desktop-tablet {
    display: none;
  }
}




*{
  overflow-x: hidden !important;
}

body{
  margin-top: -6%;
}
