@media only screen and (min-width: 1020px) and (max-width: 1450px) {

   *{
      overflow-x: hidden !important; 
   }

   .desktop-mapa-footer{
      display: none;
   }

   .desktop-h1-first{
      font-size: 50px;
   }

   .desktop-h3-first{
      font-size: 25px;
   }

   .desktop-h1-first, .desktop-h3-first{
      margin-right: -100%;
   }

   #reference{
      display:none;
   }

   .desktop-sluzby-main-text, .desktop-sluzby-second-text{
      text-align: center;
      margin-left: 0%;
   }

  

   .jecnicka, .dvojka, .trojka, .ctverka {
      font-size: 15px !important;
   }


   .obrazek1, .obrazek2{
      display: none;
   }


   .tablet-res1{
      width: 80%;
      position: relative;
      margin-left: 10%;
      border-radius: 10px;
      margin-bottom: 5%;
   }
    
   .image-container-desktop-tablet {
      margin-bottom: 5%; /* Mezera mezi kontejnery */
    }
    
    .image-text-desktop-tablet {
      margin-left: 10%; /* Zarovnání textu 10 % zleva */
      text-align: left; /* Zarovnání textu vlevo */
      margin-top: 0%; /* Mezera mezi texty */
      font-size: 14px; /* Přizpůsob velikost textu podle potřeby */
      color: #333; /* Barva textu */
    }
    
    .simple-economy-mail-desktop{
      display: none;
    }


    

    .developer-hal-ppl{
      height: 80vh;
      width: 100% !important;
      max-width: 100%; /* Zajistí, že šířka nebude nikdy větší než 100% obrazovky */
      box-sizing: border-box; /* Zajistí, že padding a border se zahrnují do šířky */
      overflow: hidden; /* Skryje scrollbar */
    }

    text{
      font-size: 18px;
      color: black;
      font-weight: bold;
    }

    .desktop-kontakt-1-second{
      color: white;
      font-weight: normal;
    }


    .desktop-kontakt-background{
      position: sticky;
      width: 100%;
      height: 70vh;
      margin-top: 15%;
      background-color: rgb(22, 22, 114);
      margin-bottom: -10%;
    }

    .desktop-kontakt-3{
      color: white;
      font-weight: bold;
      background-color: #161672;
      margin-top: 5%;
    }

    .desktop-kontakt-second-text{
      margin-top: 0%;
    }

  }